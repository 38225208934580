<script setup lang="ts">
import type { ComputedRef, PropType } from 'vue'
import { computed } from 'vue-demi'
import type { AlertType } from '~/types/alert'

const props = defineProps({
  type: {
    type: String as PropType<AlertType>,
    required: true,
  },
  hasClose: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['close'])

const iconType = computed<string>(
  () => ['info', 'error', 'warning', 'success'].includes(props.type)
    ? `b2b_icon-${props.type}`
    : 'b2b_icon-warning',
)

const alertTypeClass = computed<string | null>(
  () => ['info', 'error', 'warning', 'success'].includes(props.type)
    ? `cf-alert--type-${props.type}`
    : null,
)

const fillCloseColor: ComputedRef<string> = computed(() =>
  ['success', 'error', 'info'].includes(props.type) ? 'white' : '#222222',
)

const classes = computed<string[]>(
  () => [alertTypeClass.value].filter(Boolean) as string[],
)

defineExpose({
  classes,
  iconType,
})
</script>

<template>
  <div class="cf-alert" role="alert" :class="classes">
    <div class="cf-alert__inner">
      <b2b-icon :icon="iconType" color="warning-100" size="100" />
      <p>
        <slot />
      </p>
    </div>
    <div class="cf-alert__actions">
      <slot name="action" />
      <button v-if="hasClose" type="button" class="cf-alert__button" @click="$emit('close')">
        <svg :width="24" :height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6974 11.9899L19.0942 5.59318C19.27 5.39488 19.261 5.09392 19.0736 4.90651C18.8862 4.71909 18.5852 4.71007 18.3869 4.88593L11.9902 11.2827L5.59343 4.88593C5.39513 4.71007 5.09417 4.71909 4.90675 4.90651C4.71934 5.09392 4.71032 5.39488 4.88618 5.59318L11.2829 11.9899L4.88618 18.3867C4.71291 18.5851 4.72294 18.8839 4.90912 19.0703C5.09531 19.2566 5.39404 19.267 5.59268 19.0939L11.9894 12.6972L18.3862 19.0939C18.4798 19.1878 18.6069 19.2406 18.7394 19.2406C18.872 19.2406 18.9991 19.1878 19.0927 19.0939C19.2875 18.8984 19.2875 18.5822 19.0927 18.3867L12.6959 11.9899H12.6974Z" :fill="fillCloseColor" />
        </svg>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cf-alert {
  flex: 0 1 40px;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  &--type {
    &-info {
      background: #008FFD;
      color: #ffffff;
    }

    &-success {
      background: #50CC7F;
      color: #ffffff;
    }

    &-error {
      background: #F24E63;
      color: #ffffff;
    }

    &-warning {
      background: #FFDF33;
      color: #000000;
    }
  }

  &__inner {
    flex: auto;
    display: inherit;
    padding: 8px 14px 8px 12px;

    & b2b-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      fill: currentColor;
    }

    & p {
      margin: 0;
    }
  }

  &__button {
    all: unset;
    flex: 0 0 20px;
    position: relative;
    display: inherit;
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 14px 8px 0;
  }
}
</style>
