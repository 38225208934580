export const ENVIRONMENTS = ['dev', 'int', 'prod']

export const REGISTERED_SERVICES_INFO = {
  dashboard: {
    id: 'dashboard',
    title: 'Dashboard',
    icon: 'home',
  },
  article: {
    id: 'article',
    title: 'Artikel',
    icon: 'price-tag',
  },
  wizard: {
    id: 'wizard',
    title: 'Briefings',
    icon: 'newsletter-sign-up',
  },
  job_platform: {
    id: 'job-platform',
    title: 'Aufträge',
    icon: 'order',
  },
  my_orders: {
    id: 'my-orders',
    title: 'Meine Aufträge',
    icon: 'order-form',
  },
  my_orders_labels: {
    id: 'labels',
    title: 'Etiketten',
    icon: 'label',
  },
  logistics: {
    id: 'logistics',
    title: 'Logistik',
    icon: 'delivery-express',
  },
  content_templates: {
    id: 'content-templates',
    title: 'Content Vorlagen',
    icon: 'order-form',
  },
  clear_trap: {
    id: 'clear-trap',
    title: 'Klarfalle',
    icon: 'chat',
  },
  service_provider: {
    id: 'service-provider',
    title: 'Dienstleister',
    icon: 'delivery-flat',
  },
  service_packages: {
    id: 'service-packages',
    title: 'Service Pakete',
    icon: 'puzzle',
  },
  bills: {
    id: 'bills',
    title: 'Rechungen',
    icon: 'e-invoice',
  },
}

export const SUPPORT_URL = 'https://og2gether.sharepoint.com/sites/PoCautom_Beauftragung/SitePages/ContentFactoryLearningHub.aspx'
