<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, onMounted } from 'vue-demi'
import { getServices } from '~/main'
import { ENVIRONMENTS, REGISTERED_SERVICES_INFO } from '~/modules/constants'

import type { MenuItem, MenuItemExtended, SideNavOptions } from '~/types/sideNav'
import { UserRole } from '~/types/sideNav'

import CfIcon from '~/components/CfIcon.vue'

const props = defineProps({
  environment: {
    type: String,
    required: true,
    validator(value: string) {
      return ENVIRONMENTS.includes(value)
    },
  },
  activeServiceUrl: {
    type: String,
    required: true,
  },
  menuOptions: {
    type: Object as PropType<SideNavOptions>,
    required: false,
  },
  userRole: {
    type: String,
    required: true,
  },
  externalNavigation: Boolean,
})

const emit = defineEmits(['init', 'menu-item:click', 'logo:click'])

const {
  dashboard,
  job_platform,
  wizard,
  my_orders,
  my_orders_labels,
  logistics,
} = REGISTERED_SERVICES_INFO

const icons = import.meta.glob('../../../public/assets/icons/*.svg', {
  as: 'raw',
  eager: true,
})

const getIconSvgTemplate = (iconName: string) => icons[`../../../public/assets/icons/${iconName}.svg`]
const getNotificationCount = (id: string) => props.menuOptions?.[id]?.notificationCount ?? 0

const logoClickHandler = props.externalNavigation
  ? (e: MouseEvent) => {
      e.preventDefault()

      const eventPayload = {
        id: dashboard.id,
        serviceUrl: getServices(props.environment)[dashboard.id],
      }

      // eslint-disable-next-line vue/custom-event-name-casing
      emit('logo:click', eventPayload)

      return false
    }
  : undefined

const clickHandler = function (serviceId: string) {
  return function (e: MouseEvent) {
    e.preventDefault()

    const eventPayload = {
      id: serviceId,
      serviceUrl: getServices(props.environment)[serviceId],
    }
    // eslint-disable-next-line vue/custom-event-name-casing
    emit('menu-item:click', eventPayload)

    return false
  }
}

const homeClickHandler = props.externalNavigation ? clickHandler(dashboard.id) : undefined

const menuItemsDef: MenuItem[] = [
  {
    id: wizard.id,
    title: wizard.title,
    iconTemplate: getIconSvgTemplate(wizard.icon),
    serviceUrl: getServices(props.environment)[wizard.id],
    notificationCount: getNotificationCount(wizard.id),
    clickHandler: props.externalNavigation ? clickHandler(wizard.id) : undefined,
    permissions: [UserRole.CF_MANAGER, UserRole.CF_REQUESTER],
  },
  {
    id: job_platform.id,
    title: job_platform.title,
    iconTemplate: getIconSvgTemplate(job_platform.icon),
    serviceUrl: getServices(props.environment)[job_platform.id],
    notificationCount: getNotificationCount(job_platform.id),
    clickHandler: props.externalNavigation ? clickHandler(job_platform.id) : undefined,
    permissions: [UserRole.CF_MANAGER, UserRole.CF_SERVICE_PROVIDER],
  },
  {
    id: my_orders.id,
    title: my_orders.title,
    iconTemplate: getIconSvgTemplate(my_orders.icon),
    serviceUrl: getServices(props.environment)[my_orders.id],
    notificationCount: getNotificationCount(my_orders.id),
    clickHandler: props.externalNavigation ? clickHandler(my_orders.id) : undefined,
    permissions: [UserRole.CF_MANAGER, UserRole.CF_REQUESTER],
  },
  {
    id: my_orders_labels.id,
    title: my_orders_labels.title,
    iconTemplate: getIconSvgTemplate(my_orders_labels.icon),
    serviceUrl: getServices(props.environment)[my_orders_labels.id],
    notificationCount: getNotificationCount(my_orders_labels.id),
    clickHandler: props.externalNavigation ? clickHandler(my_orders_labels.id) : undefined,
    permissions: [UserRole.CF_MANAGER, UserRole.CF_REQUESTER],
  },
  {
    id: logistics.id,
    title: logistics.title,
    iconTemplate: getIconSvgTemplate(logistics.icon),
    serviceUrl: getServices(props.environment)[logistics.id],
    notificationCount: getNotificationCount(logistics.id),
    clickHandler: props.externalNavigation ? clickHandler(logistics.id) : undefined,
    permissions: [UserRole.CF_SERVICE_PROVIDER, UserRole.CF_MANAGER],
  },
]

const menuItems = computed<MenuItemExtended[]>(() => {
  return menuItemsDef
    .filter(({ permissions }: MenuItem) => permissions.map(String).includes(props.userRole))
    .map((menuItemDef: MenuItem) => ({
      ...menuItemDef,
      isActive: () => menuItemDef.serviceUrl === props.activeServiceUrl,
    }))
})

onMounted(() => {
  emit('init', menuItems.value)
})
</script>

<template>
  <aside class="cf-aside-menu">
    <div class="cf-p-8">
      <div class="cf-mb-48">
        <a :href="getServices(environment).dashboard" class="cf-aside-menu__branding" @click="logoClickHandler">
          <CfIcon
            id="cf_logo_otto" class="cf_logo pl_logo--red"
            :icon-svg="getIconSvgTemplate('otto-logo-01')"
          />
          <div class="cf-vertical-line" />
          <h4 class="cf-my-0">
            CONTENT FACTORY
          </h4>
        </a>
      </div>

      <nav class="menu">
        <header
          class="cf-menu__header cf_headline50"
          :class="{ 'cf-list-item--active': activeServiceUrl === getServices(environment).dashboard }"
        >
          <CfIcon :icon-svg="getIconSvgTemplate('home')" class="cf-menu__header_icon" />
          <a :href="getServices(environment).dashboard" @click="homeClickHandler">
            <span class="cf-pl-8">Übersicht</span>
          </a>
        </header>
        <div class="cf-menu__items">
          <div class="cf-menu__items-title">
            Content factory Services
          </div>
          <ul class="cf-list">
            <a v-for="menuItem in menuItems" :key="menuItem.title" :href="menuItem.serviceUrl" @click="menuItem.clickHandler">
              <li class="cf-list-item" :class="{ 'cf-list-item--active': menuItem.isActive() }">
                <CfIcon :icon-svg="menuItem.iconTemplate" class="cf-list-item__icon" />
                <span class="cf-list-item__text">{{ menuItem.title }}</span>
                <span class="cf-notification">
                  {{ menuItem.notificationCount > 0 ? ` (${menuItem.notificationCount})` : '' }}
                  <span :class="menuItem.notificationCount > 0 ? 'cf-notification__badge' : ''" />
                </span>
                <div class="cf-list-item__actions" />
              </li>
            </a>
          </ul>
        </div>
      </nav>
    </div>
  </aside>
</template>

<style scoped>
.cf-aside-menu {
  background-color: white;
  height: 100%;
  width: 280px;
}

a {
  text-decoration: none;
  color: black;
}

.cf-aside-menu__branding {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.cf_logo {
  max-width: 77px;
  max-height: 26px;
  display: block;
  fill: currentColor;
  color: rgb(240, 0, 32);
}

.cf-vertical-line {
  margin-left: 7px;
  border-left: 2px solid;
  border-color: #777777;
  height: 26px;
  left: 50%;
}

.cf-aside-menu__branding h4 {
  font-weight: bold;
  color: #777777;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.26em;
  width: 78px;
  height: 32px;
  padding-left: 12px;
}

.cf-menu__header {
  border-radius: 0.25rem;
  margin-bottom: 24px;
  padding: 10px 8px;
}

.cf-menu__items-title {
  font-size: 12px;
  line-height: 16px;
  color: rgba(119, 119, 119, 1);
  text-transform: uppercase;
  padding-left: 8px;
  padding-right: 8px;
}

.cf-list {
  margin: 8px 0;
  padding-left: 0;
  padding-right: 0;
  list-style-type: none;
}

.cf-list a {
  cursor: pointer;
}

.cf-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 4px;
  padding: 10px 8px;
  color: rgba(34, 34, 34, 1);
}

.cf-list-item--active {
  border-radius: 0.25rem;
  background-color: rgba(240, 240, 240, 1);
  font-weight: 700;
}

.cf-notification {
  position: relative;
  padding-left: 3px;
  padding-right: 5px;
}

.cf-notification__badge {
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
}

.cf-menu__header svg {
  margin-right: 8px;
}

.cf-menu__header_icon {
  display: inline-block;
  vertical-align: bottom;
}

.cf-menu__header_icon,
.cf-list-item__icon {
  width: 24px;
  height: 24px;
}

.cf-list-item__text {
  padding-left: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.cf-list-item--active .cf-list-item__text {
  font-weight: 700;
}

.cf-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.cf-pl-8 {
  padding-left: 8px;
}
.cf-p-8 {
  padding: 2rem;
}

.cf-mb-48 {
  margin-bottom: 48px;
}

.cf_headline50 {
  font-size: 14px;
  line-height: 24px;
}
</style>
