export interface MenuItem {
  id: string
  title: string
  iconTemplate: string
  serviceUrl: string
  notificationCount: number
  clickHandler: ((e: MouseEvent) => boolean) | undefined
  permissions: UserRole[]
}

export type MenuItemExtended = MenuItem & { isActive: () => boolean }

export interface ServiceUrls {
  [environment: string]: {
    [service: string]: string
  }
}

export interface SideNavItemOptions {
  notificationCount: number
}

export interface SideNavOptions {
  [id: string]: SideNavItemOptions
}

export enum UserRole {
  CF_MANAGER = 'OTTO-CONTENT-FACTORY-CF-MANAGER',
  CF_REQUESTER = 'OTTO-CONTENT-FACTORY-CF-REQUESTER',
  CF_SERVICE_PROVIDER = 'OTTO-CONTENT-FACTORY-CF-SERVICE-PROVIDER',
}
