import type { SERVICE_INFO } from '~/types/sideNav'

export const ENVIRONMENTS = ['dev', 'int', 'prod']

export const REGISTERED_SERVICES_INFO: Record<string, SERVICE_INFO> = {
  dashboard: {
    id: 'dashboard',
    titleTranslations: {
      de: 'Übersicht',
      en: 'Dashboard',
    },
    icon: 'home',
  },
  article: {
    id: 'article',
    titleTranslations: {
      de: 'Artikel',
      en: 'Artikel',
    },
    icon: 'price-tag',
  },
  wizard: {
    id: 'wizard',
    titleTranslations: {
      de: 'Briefings',
      en: 'Briefings',
    },
    icon: 'newsletter-sign-up',
  },
  job_platform: {
    id: 'job-platform',
    titleTranslations: {
      de: 'Aufträge',
      en: 'Orders',
    },
    icon: 'order',
  },
  my_orders: {
    id: 'my-orders',
    titleTranslations: {
      de: 'Meine Aufträge',
      en: 'My Orders',
    },
    icon: 'order-form',
  },
  my_orders_labels: {
    id: 'labels',
    titleTranslations: {
      de: 'Etiketten',
      en: 'Labels',
    },
    icon: 'label',
  },
  logistics: {
    id: 'logistics',
    titleTranslations: {
      de: 'Logistik',
      en: 'Logistics',
    },
    icon: 'delivery-express',
  },
  content_templates: {
    id: 'content-templates',
    titleTranslations: {
      de: 'Content Vorlagen',
      en: 'Content Vorlagen',
    },
    icon: 'order-form',
  },
  clear_trap: {
    id: 'clear-trap',
    titleTranslations: {
      de: 'Klarfalle',
      en: 'Klarfalle',
    },
    icon: 'chat',
  },
  service_provider: {
    id: 'service-provider',
    titleTranslations: {
      de: 'Dienstleister',
      en: 'Dienstleister',
    },
    icon: 'delivery-flat',
  },
  service_packages: {
    id: 'service-packages',
    titleTranslations: {
      de: 'Service Pakete',
      en: 'Service Pakete',
    },
    icon: 'puzzle',
  },
  bills: {
    id: 'bills',
    titleTranslations: {
      de: 'Rechungen',
      en: 'Rechungen',
    },
    icon: 'e-invoice',
  },
}

export const SUPPORT_URL = 'https://og2gether.sharepoint.com/sites/PoCautom_Beauftragung/SitePages/ContentFactoryLearningHub.aspx'
