<script setup lang="ts">
import { computed } from 'vue-demi'
import CfIcon from '~/components/CfIcon.vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  hasClose: {
    type: Boolean,
    default: false,
  },
  headerBackground: {
    type: String,
    default: '',
  },
  headerText: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    required: false,
  },
  hideCancel: {
    type: Boolean,
    default: false,
  },
  cancelText: {
    type: String,
    default: '',
  },
  confirmText: {
    type: String,
    default: '',
  },
  disableConfirm: {
    type: Boolean,
    default: false,
  },
  disableCancel: {
    type: Boolean,
    default: false,
  },
  loadingConfirm: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['cancel', 'ok'])

const iconColor = computed(() => props.headerBackground === 'warning' ? '#000000' : '#ffffff')

function checkValue(value: string): boolean {
  switch (value) {
    case 'true':
      return true
    default:
      return false
  }
}

const loadingConfirmButton = computed(() => {
  const loadingValue = String(props.loadingConfirm)
  return checkValue(loadingValue)
})

const disableButtons = computed(() => {
  const disableConfirmValue = String(props.disableConfirm)
  const disableCancelValue = String(props.disableCancel)

  return {
    confirm: checkValue(disableConfirmValue),
    cancel: checkValue(disableCancelValue),
  }
})

const confirmView = computed(() => disableButtons.value.confirm ? 'secondary' : 'primary')

const icons = import.meta.glob('../../../public/assets/icons/*.svg', {
  as: 'raw',
  eager: true,
})
const getSpinnerSvg = (iconName: string) => icons[`../../../public/assets/icons/${iconName}.svg`]
</script>

<template>
  <cf-modal
    :is-open="isOpen"
    :header-background="headerBackground"
    height="205px"
    :width="width"
    class="cf-confirm-modal"
  >
    <div slot="header" class="cf-confirm-modal__header">
      <cf-title tag="h2" class="cf-confirm-modal__header-title">
        {{ headerText }}
      </cf-title>
      <b2b-icon
        v-if="hasClose"
        icon="b2b_icon-close"
        :fill="iconColor"
        size="100"
        class="cf-confirm-modal__close_icon"
        @click="$emit('cancel')"
      />
    </div>

    <div class="cf-confirm-modal__body">
      <slot />
    </div>

    <div slot="navigation" class="cf-confirm-modal__navigation">
      <cf-button
        v-if="!hideCancel"
        :fit-content="true"
        :disabled="disableButtons.cancel"
        view="secondary"
        class="cf-confirm-modal__cancel-button"
        :size="50"
        @click="$emit('cancel')"
      >
        {{ cancelText }}
      </cf-button>

      <cf-button
        :fit-content="true"
        :view="confirmView"
        :disabled="disableButtons.confirm"
        :progress="loadingConfirmButton"
        class="cf-confirm-modal__ok-button"
        :size="50"
        @click="$emit('ok')"
      >
        <template v-if="loadingConfirmButton">
          <div class="cf-confirm-modal__ok-button--spinner">
            <CfIcon :icon-svg="getSpinnerSvg('spinner')" />
          </div>
        </template>
        <template v-else>
          {{ confirmText }}
        </template>
      </cf-button>
    </div>
  </cf-modal>
</template>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cf-confirm-modal {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-weight: 700;
      margin: 0;
    }
  }

  &__navigation {
    display: inherit;
    position: relative;
  }

  &__close_icon {
    cursor: pointer;
  }

  &__ok-button {
    margin-left: auto;
    &--spinner {
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;
    }
  }
}
</style>
