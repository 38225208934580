<script setup lang="ts">
import { computed } from 'vue-demi'
import type { PropType } from 'vue'

const props = defineProps({
  tag: {
    type: String as PropType<string>,
    default: 'h1',
    validator(value: string) {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
    },
  },
})

const isTag = computed(() => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(props.tag))

const tag = computed(() =>
  isTag.value
    ? props.tag
    : 'h1',
)

const tagClasses = computed(() => ({
  [`cf-title--${tag.value}`]: isTag.value,
}))

const classes = computed(() => [tagClasses.value])

defineExpose({
  tag,
  classes,
})
</script>

<template>
  <component :is="tag" class="cf-title" :class="classes">
    <slot />
  </component>
</template>

<style lang="scss">
.cf-title {
  &--h1 {
    @apply text-300;
  }

  &--h2 {
    @apply text-250;
    font-size: 20px;
    line-height: 24px;
  }

  &--h3 {
    @apply text-100;
  }

  &--h4 {
    @apply text-50;
  }

  &--h5 {
    @apply text-25;
  }

  &--h6 {
    @apply text-10;
  }
}
</style>
