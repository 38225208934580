<script lang="ts" setup>
import { inject } from 'vue-demi'

interface TabProps {
  name: string
}

defineProps<TabProps>()

const active = inject('activeTab')
</script>

<template>
  <div
    @click="active = name"
  >
    <slot name="tab">
      <div
        class="cf-tab"
        :class="{ 'cf-tab--active': name === active }"
      >
        <slot name="tab-content">
          {{ name }}
        </slot>
      </div>
    </slot>
  </div>
</template>

<style scoped lang="scss">
.cf-tab {
  cursor: pointer;
  padding: 4px 12px 4px 12px;
  gap: 4px;
  color: #777777;
  background-color: #FFFFFF;
  border-bottom: 2px solid #E6E6E6;
  margin-bottom: -2px;
  &--active {
    color: #222222;
    border-bottom: 2px solid #222222;
  }
}
</style>
