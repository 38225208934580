import { defineCustomElement } from 'vue'

import './style.css'

import serviceUrlsJson from './modules/serviceUrls.json'

import SideNav from './components/side-nav/SideNav.ce.vue'
import Support from './components/support/Support.ce.vue'
import Checkbox from '~/components/checkbox/Checkbox.ce.vue'
import Tabs from '~/components/tabs/Tabs.ce.vue'
import Tab from '~/components/tabs/Tab.ce.vue'
import TabContent from '~/components/tabs/TabContent.ce.vue'
import Alert from '~/components/alert/Alert.ce.vue'
import Title from '~/components/title/Title.ce.vue'
import Button from '~/components/button/Button.ce.vue'
import Modal from '~/components/modals/Modal.ce.vue'
import ConfirmModal from '~/components/modals/ConfirmModal.ce.vue'

import type { ServiceUrls } from '~/types/sideNav'

const CFSideNav = defineCustomElement(SideNav)
const CFSupport = defineCustomElement(Support)
const CFCheckbox = defineCustomElement(Checkbox)
const CFTabs = defineCustomElement(Tabs)
const CFTab = defineCustomElement(Tab)
const CFTabContent = defineCustomElement(TabContent)
const CFAlert = defineCustomElement(Alert)
const CFTitle = defineCustomElement(Title)
const CFButton = defineCustomElement(Button)
const CFModal = defineCustomElement(Modal)
const CFConfirmModal = defineCustomElement(ConfirmModal)
// export individual elements
export { CFSideNav, CFSupport, CFCheckbox }

export function getServices(environment: string) {
  const serviceUrls: ServiceUrls = serviceUrlsJson
  return serviceUrls[environment]
}

export function register() {
  customElements.define('cf-side-nav', CFSideNav)
  customElements.define('cf-support', CFSupport)
  customElements.define('cf-checkbox', CFCheckbox)
  customElements.define('cf-tabs', CFTabs)
  customElements.define('cf-tab', CFTab)
  customElements.define('cf-tab-content', CFTabContent)
  customElements.define('cf-alert', CFAlert)
  customElements.define('cf-title', CFTitle)
  customElements.define('cf-button', CFButton)
  customElements.define('cf-modal', CFModal)
  customElements.define('cf-confirm-modal', CFConfirmModal)
}
