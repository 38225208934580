<script setup lang="ts">
import { provide, ref } from 'vue-demi'
import { watch } from 'vue'

interface TabsProps {
  value: string
  vertical?: boolean
  tabListWidth?: string
  tabContentWidth?: string
}

interface TabsEmits {
  (e: 'changeTab', name: string): void
}

const props = withDefaults(defineProps<TabsProps>(), {
  tabListWidth: '100%',
  tabContentWidth: '100%',
})

const emits = defineEmits<TabsEmits>()

const value = ref(props.value)
provide('activeTab', value)

watch(value, changeTab)

function changeTab(value: string) {
  emits('changeTab', value)
}
</script>

<template>
  <div class="cf-tabs" :class="{ 'cf-tabs--vertical': props.vertical }">
    <div class="cf-tab__list" :style="{ width: props.tabListWidth }">
      <slot name="tabs-list" />
    </div>
    <div class="cf-tab__contents" :style="{ width: props.tabContentWidth }">
      <slot name="tabs-content" />
    </div>
  </div>
</template>

<style lang="scss">
.cf-tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #FFFFFF;

  .cf-tab__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 2px solid #E6E6E6;
  }

  .cf-tab__contents {
    margin-top: 7px;
  }

  &--vertical {
    flex-direction: row;
    .cf-tab__list {
      display: inline-block;
      border-bottom: none;
    }
    .cf-tab__contents {
      margin-top: 0;
      border-left: 1px solid #E6E6E6;
    }
  }
}
</style>
