<script setup lang="ts">
import { inject } from 'vue-demi'

interface TabContentProps {
  name: string
}

defineProps<TabContentProps>()

const active = inject('activeTab')
</script>

<template>
  <div v-show="active === name" class="cf-tab--content">
    <slot name="content" />
  </div>
</template>

<style scoped>

</style>
