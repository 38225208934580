<script setup lang="ts">
import { computed } from 'vue-demi'

export interface CheckboxProps {
  modelValue: boolean
  label: string
  disabled?: boolean
  indeterminate?: boolean
  invalid?: boolean
}

interface CheckboxEmits {
  (e: 'update:modelValue', value: boolean): void
}

const props = defineProps<CheckboxProps>()

const emit = defineEmits<CheckboxEmits>()

const value = computed(() => props.indeterminate ? false : props.modelValue)

function handleInputChange(event: InputEvent) {
  const eventTarget = event.target as HTMLInputElement
  const value = eventTarget.checked

  emit('update:modelValue', !value)
}

const checkboxClasses = computed(() => {
  return {
    'checkbox__element--indeterminate': props.indeterminate,
    'checkbox__element--error': props.invalid,
  }
})

defineExpose({
  handleInputChange,
})
</script>

<template>
  <div class="checkbox">
    <input
      v-bind="{ ...$attrs, disabled }"
      :checked="value"
      type="checkbox"
      class="checkbox__element"
      :class="checkboxClasses"
      @change="handleInputChange"
    >

    <label v-if="label" class="checkbox__label">
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss">
.checkbox {
  display: flex;
  align-items: center;

  &__label {
    margin-left: 16px;
  }

  &__element {
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    &:after {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #c4c4c4;
      content: "";
      text-align: center;
    }

    &:checked:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjM0MTc3IDExLjQ3ODJDNS4xMjYwMiAxMS40NzgyIDQuOTEwOTEgMTEuMzk2IDQuNzQ1NzggMTEuMjMyMUwwLjIzMzQ2IDYuNzMwNTVDLTAuMDgyODQ0NSA2LjM5OTE1IC0wLjA3NzAyNTcgNS44NzU5MyAwLjI0NjU3MSA1LjU1MTY1QzAuNTcwMTY3IDUuMjI3MzYgMS4wOTMzNyA1LjIyMDQzIDEuNDI1NDQgNS41MzYwM0w1LjM0MTc3IDkuNDQyODdMMTQuNTc0NiAwLjIzMjIyNUMxNC45MDY2IC0wLjA4MzM3NTMgMTUuNDI5OCAtMC4wNzY0NDY5IDE1Ljc1MzQgMC4yNDc4MzdDMTYuMDc3IDAuNTcyMTIgMTYuMDgyOCAxLjA5NTM0IDE1Ljc2NjUgMS40MjY3NEw1LjkzNzc2IDExLjIzMjFDNS43NzMyNiAxMS4zOTYgNS41NTc1MSAxMS40NzgyIDUuMzQxNzcgMTEuNDc4MloiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==");
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
    }

    &:disabled {
      cursor: not-allowed;
      &:after {
        background-color: #ffffff;
        border-color: #f0f0f0;
      }

      & + .checkbox__label {
        color: #777777;
      }

      &:checked:after {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M8.852 18.807a1 1 0 01-.706-.292l-5.35-5.336a1 1 0 011.414-1.416l4.642 4.63L19.797 5.476a1 1 0 011.413 1.416L9.559 18.515a.998.998 0 01-.707.292z' fill='gray'/%3E%3C/g%3E%3C/svg%3E");
      }
    }

    &--indeterminate {
      &:after {
        background-color: #e6e6e6;
        border-color: #c4c4c4;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjM0MTc3IDExLjQ3ODJDNS4xMjYwMiAxMS40NzgyIDQuOTEwOTEgMTEuMzk2IDQuNzQ1NzggMTEuMjMyMUwwLjIzMzQ2IDYuNzMwNTVDLTAuMDgyODQ0NSA2LjM5OTE1IC0wLjA3NzAyNTcgNS44NzU5MyAwLjI0NjU3MSA1LjU1MTY1QzAuNTcwMTY3IDUuMjI3MzYgMS4wOTMzNyA1LjIyMDQzIDEuNDI1NDQgNS41MzYwM0w1LjM0MTc3IDkuNDQyODdMMTQuNTc0NiAwLjIzMjIyNUMxNC45MDY2IC0wLjA4MzM3NTMgMTUuNDI5OCAtMC4wNzY0NDY5IDE1Ljc1MzQgMC4yNDc4MzdDMTYuMDc3IDAuNTcyMTIgMTYuMDgyOCAxLjA5NTM0IDE1Ljc2NjUgMS40MjY3NEw1LjkzNzc2IDExLjIzMjFDNS43NzMyNiAxMS4zOTYgNS41NTc1MSAxMS40NzgyIDUuMzQxNzcgMTEuNDc4MloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
      }
    }

    &--error {
      &:after {
        background-color: #ffffff;
        border-color: #f24e63;
      }
      &:checked:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjM0MTc3IDExLjQ3ODJDNS4xMjYwMiAxMS40NzgyIDQuOTEwOTEgMTEuMzk2IDQuNzQ1NzggMTEuMjMyMUwwLjIzMzQ2IDYuNzMwNTVDLTAuMDgyODQ0NSA2LjM5OTE1IC0wLjA3NzAyNTcgNS44NzU5MyAwLjI0NjU3MSA1LjU1MTY1QzAuNTcwMTY3IDUuMjI3MzYgMS4wOTMzNyA1LjIyMDQzIDEuNDI1NDQgNS41MzYwM0w1LjM0MTc3IDkuNDQyODdMMTQuNTc0NiAwLjIzMjIyNUMxNC45MDY2IC0wLjA4MzM3NTMgMTUuNDI5OCAtMC4wNzY0NDY5IDE1Ljc1MzQgMC4yNDc4MzdDMTYuMDc3IDAuNTcyMTIgMTYuMDgyOCAxLjA5NTM0IDE1Ljc2NjUgMS40MjY3NEw1LjkzNzc2IDExLjIzMjFDNS43NzMyNiAxMS4zOTYgNS41NTc1MSAxMS40NzgyIDUuMzQxNzcgMTEuNDc4MloiIGZpbGw9IiNGMjRFNjMiLz4KPC9zdmc+Cg==");
      }
    }
  }
}
</style>
