<script setup lang="ts">
import type { PropType } from 'vue'

import { SUPPORT_URL } from '~/modules/constants'

defineProps({
  width: {
    type: Number as PropType<number>,
    default: 10,
  },

  height: {
    type: Number as PropType<number>,
    default: 12,
  },

  color: {
    type: String as PropType<string>,
    default: '#000000',
  },
})

defineExpose({
  SUPPORT_URL,
})
</script>

<template>
  <a class="cf-btn non-selectable no-outline cf-btn--standard cf-btn--round bg-white text-white cf-btn--actionable cf-btn--dense support-button" :href="SUPPORT_URL" target="_blank">
    <span class="cf-btn__content text-center cf-col items-center justify-center cf-row">
      <svg :width="width" :height="height" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1664 19.4963C12.1664 19.9105 11.8306 20.2463 11.4164 20.2463C11.0022 20.2463 10.6664 19.9105 10.6664 19.4963C10.6664 19.082 11.0022 18.7463 11.4164 18.7463C11.8306 18.7463 12.1664 19.082 12.1664 19.4963ZM11.4157 16.4783C11.1394 16.4783 10.9154 16.2543 10.9154 15.978V14.8035C10.9154 13.0088 12.2204 12.123 13.3717 11.3415C14.5709 10.5278 15.6059 9.82575 15.6059 8.25075C15.6059 5.667 13.4602 4.74975 11.6227 4.74975C10.4002 4.74975 9.08017 5.3355 8.26117 6.24225C8.07543 6.44606 7.76015 6.46213 7.55467 6.27825C7.35005 6.0929 7.33395 5.77694 7.51867 5.57175C8.53492 4.44825 10.1069 3.75 11.6227 3.75C14.6024 3.75 16.6057 5.55825 16.6057 8.25075C16.6057 10.3553 15.1859 11.319 13.9327 12.1695C12.8497 12.9045 11.9144 13.539 11.9144 14.8035V15.978C11.9146 16.1107 11.862 16.2381 11.7681 16.332C11.6743 16.4258 11.5469 16.4785 11.4142 16.4783H11.4157Z" :fill="color" />
      </svg>
    </span>
  </a>
</template>

<style scoped>
.bg-white {
  background: #fff!important
}

.justify-center {
  justify-content: center
}

.items-center {
  align-items: center
}

.cf-row {
  display: flex;
  flex-wrap: wrap
}

.cf-col {
  flex: 10000 1 0%
}

.non-selectable {
  user-select: none !important
}

.no-outline {
  outline: 0 !important
}

.text-white {
  color: #fff !important
}

.support-button {
  border-radius: 50%;
}

.cf-btn {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  outline: 0;
  border: 0;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.715em;
  text-decoration: none;
  color: inherit;
  background: transparent;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  height: auto;
  cursor: default;
  padding: 4px 16px;
  min-height: 2.572em
}

.cf-btn:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  box-shadow: 0 1px 5px #0003,0 2px 2px #00000024,0 3px 1px -2px #0000001f
}

.cf-btn--actionable {
  cursor: pointer
}

.cf-btn--actionable.cf-btn--standard:before {
  transition: box-shadow .3s cubic-bezier(.25,.8,.5,1)
}

.cf-btn--actionable.cf-btn--standard:active:before {
  box-shadow: 0 3px 5px -1px #0003,0 5px 8px #00000024,0 1px 14px #0000001f
}

.cf-btn--round {
  border-radius: 50%;
  padding: 0;
  min-width: 3em;
  min-height: 3em
}

.cf-btn--dense {
  padding: .285em;
  min-height: 2em
}

.cf-btn--dense.cf-btn--round {
  padding: 0;
  min-height: 2.4em;
  min-width: 2.4em
}

.cf-btn__content {
  transition: opacity .3s;
  z-index: 0
}

.cf-btn--actionable {
  cursor: pointer
}

.cf-btn--actionable.cf-btn--standard:before {
  transition: box-shadow .3s cubic-bezier(.25,.8,.5,1)
}

.cf-btn--actionable.cf-btn--standard:active:before {
  box-shadow: 0 3px 5px -1px #0003,0 5px 8px #00000024,0 1px 14px #0000001f
}
</style>
